import React from "react";
import { VideoBg } from "src/components";

export const Banner = (props: any) => {
  return (
    <div className="flex">
      <VideoBg type="relative" />

      {/* Banner Txt */}
      <div className="container mx-auto z-10 absolute top-0 bottom-0 flex flex-col h-[100%] justify-center px-[20px] sm:px-[15%] pt-60 sm:pt-[0px] pb-10">
        <div className="bg-primary w-fit p-2 px-5 mb-5 rounded-[10px]">
          <p className="text-[#000]">
            Application for Incubator Competition is now open!
          </p>
        </div>
        <h1 className="text-white">Re:verse Web3.0</h1>
        <h1 className="text-white">Business Plan</h1>
        <h1 className="text-white">Contest</h1>
        <h2 className="mt-5 text-[#9f9f9f]">Grow & start your business</h2>
        <h2 className="text-[#9f9f9f]">with the cash prize!</h2>

        {/* Start Now Subscription */}
        <button
          className="w-[100%] sm:w-[300px] primary ml-0 mt-10"
          onClick={() =>
            window.open("https://form.jotform.com/230568112856054", "_blank")
          }
        >
          Apply Now
        </button>
      </div>
    </div>
  );
};
