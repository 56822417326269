import React from "react";

const txtStyle = "text-[20px] leading-[40px] sm:text-[30px] sm:leading-[60px]";

const imgLength = 32;
export const Partners = (props: any) => {
  return (
    <div id="partners">
      <div className="mt-5 text-center">
        <p className={txtStyle}>
          Potential Partners & Supporting Organizations
        </p>

        <div className="mt-5 bg-white flex flex-row flex-wrap max-w-[800px] mx-auto drop-shadow-xl rounded-[20px] p-10 space-x-[20px] items-center justify-center">
          {new Array(imgLength).fill(imgLength, 0, imgLength).map((item, i) => {
            return (
              <img
                key={i}
                src={`/assets/logos/${i + 1}.png`}
                alt="logo"
                className="h-[30px] object-fit my-[20px]"
              />
            );
          })}
        </div>
      </div>
    </div>
  );
};
