import React, { useEffect } from "react";
import { useLocation } from "react-router";
import { Menu } from "../menu";

export const Container = (props: any) => {
  const { children, noTopPadding = false } = props;
  const location = useLocation();

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, [location.pathname]);

  return (
    <div className="m-auto min-h-screen" id="home-container">
      <Menu />
      {!noTopPadding && <div className="pt-20" />}
      {children}
    </div>
  );
};
