import React from "react";
import { Progress } from "antd";

const txtStyle = "text-[20px] leading-[40px] sm:text-[30px] sm:leading-[60px]";
const strokeColor = { "0%": "#80db50", "100%": "#e60aff" };
const progressSize = 240;
export const About = (props: any) => {
  return (
    <div id="about">
      <p className={txtStyle}>
        <span className="text-primary">RE:VERSE</span>, with the support of
        <span className="text-primary"> Asiatop</span> and{" "}
        <span className="text-primary">Syndicate Capital Group</span>, will be
        organizing a web3 competition inviting young talent including
        students/aspiring entrepreneurs of different universities to give
        pitches about useful web3 projects for Hong Kong in groups of two to
        five, particularly in the metaverse and blockchain areas. Following is
        the timeline of the competition.
      </p>

      <div className="mt-20 max-w-[800px] mx-auto" id="timeline">
        <h1 className="text-center">Major Timeline</h1>

        <div className="mt-5">
          <h3>14 March 2023</h3>
          <p className={txtStyle}>
            Opening ceremony of the web3 business plan contest at Innocentre,
            Kowloon Tong
          </p>
        </div>

        <div className="mt-5 text-left sm:text-right">
          <h3>16 March 2023</h3>
          <p className={txtStyle}>
            Welcoming online applications from students (Will be submitted on
            the reverse website)
          </p>
        </div>

        <div className="mt-5">
          <h3>14 April 2023</h3>
          <p className={txtStyle}>Deadline to submit the applications</p>
        </div>

        <div className="mt-5 text-left sm:text-right">
          <h3>21 April 2023</h3>
          <p className={txtStyle}>Announcing the top 10 candidates</p>
        </div>

        <div className="mt-5">
          <h3>05 May 2023</h3>
          <p className={txtStyle}>
            Live pitching of the top 10 candidates and closing ceremony at
            Cyberport
          </p>
        </div>
      </div>

      <div className="mt-20">
        <h1 className="text-left mb-5">Ready to get the cash prize</h1>
        <p className={txtStyle}>
          The top three positions of the competition will get cash prizes. All
          the other qualified applicants would be eligible to apply for
          consultancy of a company setup eg (an office space, company
          secretary), IT support (website, app dev, metaverse service) up to{" "}
          <span className="text-primary">HKD 100,000</span> low interest loan
          from Re:Verse and also, will have a chance to get an angel investment.
        </p>
      </div>

      <div className="mt-10 text-left sm:text-center">
        <p className={txtStyle}>Cash Prizes</p>
        <div className="grid grid-cols-1 sm:grid-cols-3 gap-[20px] mt-5">
          <div className="desktop">
            <h3>HKD 50K</h3>
            <p>Second Prize</p>
          </div>

          <div>
            <h1>HKD 100K</h1>
            <p>Grand Prize</p>
          </div>

          <div className="mobile">
            <h3>HKD 50K</h3>
            <p>Second Prize</p>
          </div>

          <div>
            <h3>HKD 25K</h3>
            <p>Third Prize</p>
          </div>
        </div>
      </div>

      <div className="mt-20">
        <h3 className="text-left">
          Eligibility criteria and rules for the competition
        </h3>
        <p className={`${txtStyle} mt-5`}>
          1. Must be university students of Hong Kong (undergrad/postgrad/MBA)
        </p>
        <p className={`${txtStyle} mt-5`}>
          2. Must be a team of minimum two members and maximum five
        </p>
        <p className={`${txtStyle} mt-5`}>
          3. Must follow the socials of Reverse including{" "}
          <span
            onClick={() =>
              window.open("https://www.instagram.com/reversedao_official/")
            }
            className="text-[#d62976] cursor-pointer"
          >
            instagram
          </span>
          ,{" "}
          <span
            onClick={() => window.open("https://twitter.com/REVDReverse")}
            className="text-[#26a7de] cursor-pointer"
          >
            twitter
          </span>
          ,{" "}
          <span
            onClick={() => window.open("https://t.me/+jpjnM8agpztiNjZl")}
            className="text-[#0088CC] cursor-pointer"
          >
            telegram
          </span>
        </p>
      </div>

      <div className="mt-20">
        <h3 className="text-left !text-primary">Submission requirement</h3>
        <p className={`${txtStyle} mt-5`}>
          Submission must include the following
        </p>
        <p className={`${txtStyle} mt-5 ml-5`}>
          1. Pain point ( the problem that you are solving)
        </p>
        <p className={`${txtStyle} mt-5 ml-5`}>2. Technology deployed</p>
        <p className={`${txtStyle} mt-5 ml-5`}>
          3. Proof of concept ( if the idea is feasible or not)
        </p>
        <p className={`${txtStyle} mt-5 ml-5`}>4. Team</p>
        <p className={`${txtStyle} mt-5`}>
          Maximum of 10 slides allowed including the cover page (screenshots not
          included) ppt, pdf will be accepted
        </p>
      </div>

      <div className="mt-20">
        <h3 className="text-left">
          Judging Criteria (for shortlisting top 10 candidates)
        </h3>

        <div className="grid grid-cols-1 sm:grid-cols-2 gap-10 mt-5 text-center">
          <div>
            <Progress
              type="circle"
              percent={30}
              strokeColor={strokeColor}
              size={progressSize}
            />
            <p className="mt-2">Business Potential</p>
          </div>
          <div>
            <Progress
              type="circle"
              percent={30}
              strokeColor={strokeColor}
              size={progressSize}
            />
            <p className="mt-2">Technology Deployed</p>
          </div>
          <div>
            <Progress
              type="circle"
              percent={20}
              strokeColor={strokeColor}
              size={progressSize}
            />
            <p className="mt-2">Ease of Integration</p>
          </div>
          <div>
            <Progress
              type="circle"
              percent={20}
              strokeColor={strokeColor}
              size={progressSize}
            />
            <p className="mt-2">Quality of Pitch</p>
          </div>
        </div>

        <p className={`${txtStyle} mt-5`}>
          For the final round at Cyberport, the quality of pitch will be changed
          to 15% and 5% will be allocated to presentation. There will be a bonus
          of 5 percent for prototype (if any)
        </p>
      </div>
    </div>
  );
};
