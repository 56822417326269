import React from "react";

const txtStyle = "text-[20px] leading-[40px] sm:text-[30px] sm:leading-[60px]";
export const Organizer = (props: any) => {
  return (
    <div id="organizer">
      <div className="mt-5 text-left sm:text-right">
        <p className={txtStyle}>Organizer</p>
        <h1 className="sm:text-[140px] sm:leading-[140px]">RE:VERSE</h1>
      </div>

      <div className="mt-5 text-left">
        <div className="flex flex-row items-center space-x-[20px]">
          <p className={`${txtStyle} pr-[20px]`}>Co-Organizer</p>
          <img
            src="/assets/logos/AT_logo.png"
            alt="at-logo"
            className="h-[80px]"
          />
          <img
            src="/assets/logos/syndicate.png"
            alt="syndicate-logo"
            className="h-[80px]"
          />
        </div>
        <div className="flex flex-row items-center space-x-[20px]">
          <h1 className="sm:text-[80px] sm:leading-[100px] text-[#EA437A]">
            AsiaTop
          </h1>
        </div>
        <h1 className="sm:text-[80px] sm:leading-[100px] text-[#C8A606]">
          Syndicate Capital Group
        </h1>
      </div>

      <div className="mt-5 text-left sm:text-right">
        <div className="flex flex-row sm:flex-row-reverse items-center space-x-[20px]">
          <p className={txtStyle}>Sponsor</p>
          <img
            src="/assets/logos/Titan Asset Management.png"
            alt="titan-logo"
            className="h-[80px]"
          />
        </div>

        <h1 className="sm:text-[80px] sm:leading-[100px] text-[#DF1C1A]">
          Titan Asset Management
        </h1>
      </div>

      {/* <div className="mt-10 text-left sm:text-center">
        <p className={txtStyle}>Guest of Honors</p>
        <div className="grid grid-cols-1 sm:grid-cols-3 gap-[20px] mt-5">
          <div>
            <h3>King Lueng</h3>
            <p>Head of Fintech, InvestHK</p>
          </div>

          <div>
            <h3>Cyberport</h3>
            <p>Mr. Victor Yim / Mr. Peter Yan</p>
          </div>

          <div>
            <h3>Representatives</h3>
            <p>From Syndicate Capital Group / Re:Verse</p>
          </div>
        </div>
      </div> */}
    </div>
  );
};
