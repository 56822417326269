import React from "react";
import { Container, Footer } from "../../components";
import { About } from "./about";
import { Banner } from "./banner";
import { Organizer } from "./organizer";
import { Partners } from "./partners";

export const Home = (props: any) => {
  return (
    <Container noTopPadding={true}>
      <Banner />
      <div className="pb-20 container mx-auto px-[20px]">
        <div className="mt-20">
          <About />
        </div>
        <div className="mt-20">
          <Organizer />
        </div>
        <div className="mt-20">
          <Partners />
        </div>
        <div className="mt-20">
          <Footer />
        </div>
      </div>
    </Container>
  );
};
