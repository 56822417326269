import React from "react";

export const VideoBg = (props: any) => {
  const { type = "fixed" } = props;
  return (
    <div className={`${type} min-h-screen`}>
      {/* bg */}
      <div className={`top-0 left-0 z-0 ${type} min-h-screen`}>
        <video autoPlay muted loop className="min-h-screen">
          <source src="/assets/Digital_Grapes.mp4" type="video/mp4" />
        </video>
      </div>

      {/* Opacity */}
      <div className="opacity z-10 min-h-screen" />
    </div>
  );
};
