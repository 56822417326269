import React from "react";
import { beforeUpload, getBase64 } from "src/utils";
import ImgCrop from "antd-img-crop";
import { t } from "i18next";
import { API_URL } from "src/config";
const { Upload: AntdUpload } = require("antd");

export const Upload = (props: any) => {
  const { children, setData } = props;

  const handleChange = (info: any) => {
    if (info.file.status === "uploading") {
      return;
    }
    if (info.file.status === "done") {
      // Get this url from response in real world.
      getBase64(info.file.originFileObj, (url) => {
        setData(url);
      });
    }
  };

  return (
    <ImgCrop
      modalTitle={t("action.updateImage")}
      modalOk={t("action.modalOk")}
      modalCancel={t("action.modalCancel")}
    >
      <AntdUpload
        name="avatar"
        className="w-full h-full block"
        showUploadList={false}
        beforeUpload={beforeUpload}
        onChange={handleChange}
        action={`${API_URL}/upload/image`}
      >
        {children}
      </AntdUpload>
    </ImgCrop>
  );
};
