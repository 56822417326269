import React from "react";
import { history } from "src/stores";

const MENU_TXT_STYLE =
  "text-[13px] text-white mr-8 cursor-pointer desktop duration-700 hover:!text-primary";

const MENUS = [
  {
    id: "about",
    name: "About",
  },
  {
    id: "timeline",
    name: "Timeline",
  },
  {
    id: "parters",
    name: "Partners",
  },
];

export const Menu = (props: any) => {
  return (
    <div className="absolute top-0 left-0 w-[100%] min-h-[40px] flex flex-row py-[30px] px-[20px] sm:px-10 z-[500]">
      {/* Logo */}
      <img
        src="/assets/reverse_logo.png"
        alt="logo"
        className="w-[150px] cursor-pointer"
        onClick={() => history.push("/")}
      />

      {/* Menu */}
      <div className="flex-1 flex flex-row justify-end items-center">
        {MENUS.map((item, i) => {
          return (
            <p
              className={MENU_TXT_STYLE}
              key={i}
              onClick={() => {
                const id = document.getElementById(item.id);
                if (!id) return;
                id.scrollIntoView({ behavior: "smooth" });
              }}
            >
              {item.name}
            </p>
          );
        })}
        <button
          className="primary !bg-primary !text-black !border-[0px]"
          onClick={() =>
            window.open(
              "https://www.instagram.com/reversedao_official/",
              "_blank"
            )
          }
        >
          Contact Us
        </button>
      </div>
    </div>
  );
};
